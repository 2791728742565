import React from "react";
import { Box } from "@mui/material";
import PropTypes from "prop-types";
import PaymentForm from "./PaymentForm";
import BoookingStepsDestkop from "./BoookingStepsDestkop";
import BookingStepMobile from "./BookingStepMobile";

const BookingsForm = ({
  bookingForTPV,
  loadingSlots,
  selectedDate,
  onSelectedDate,
  slots,
  extras,
  errors,
  booking,
  onChange,
  selectedSlot,
  onChangeStep,
  onSubmit,
  onExtraChange,
  noSlotsAvailable,
}) => {
  return (
    <>
      <Box
        mt={3}
        sx={{
          display: {
            xs: "block",
            sm: "block",
            md: "block",
            lg: "none",
            xl: "none",
          },
        }}
      >
        <BookingStepMobile
          onChangeStep={onChangeStep}
          loadingSlots={loadingSlots}
          selectedDate={selectedDate}
          onSelectedDate={onSelectedDate}
          slots={slots}
          extras={extras}
          errors={errors}
          booking={booking}
          onChange={onChange}
          selectedSlot={selectedSlot}
          onSubmit={onSubmit}
          onExtraChange={onExtraChange}
          noSlotsAvailable={noSlotsAvailable}
        />
      </Box>
      <Box
        mt={3}
        sx={{
          display: {
            xs: "none",
            sm: "none",
            md: "none",
            lg: "block",
            xl: "block",
          },
        }}
      >
        <BoookingStepsDestkop
          loadingSlots={loadingSlots}
          selectedDate={selectedDate}
          onSelectedDate={onSelectedDate}
          slots={slots}
          extras={extras}
          errors={errors}
          booking={booking}
          onChange={onChange}
          selectedSlot={selectedSlot}
          onChangeStep={onChangeStep}
          onSubmit={onSubmit}
          onExtraChange={onExtraChange}
          noSlotsAvailable={noSlotsAvailable}
        />
      </Box>
      {bookingForTPV && (
        <PaymentForm
          booking={bookingForTPV.booking}
          tpvOptions={bookingForTPV.tpvOptions}
        />
      )}
    </>
  );
};

BookingsForm.propTypes = {
  bookingForTPV: PropTypes.object,
  loadingSlots: PropTypes.bool.isRequired,
  selectedDate: PropTypes.any.isRequired,
  onSelectedDate: PropTypes.func.isRequired,
  slots: PropTypes.array.isRequired,
  extras: PropTypes.array.isRequired,
  errors: PropTypes.object.isRequired,
  booking: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  selectedSlot: PropTypes.object,
  onChangeStep: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onExtraChange: PropTypes.func.isRequired,
  noSlotsAvailable: PropTypes.bool.isRequired,
};

export default BookingsForm;
