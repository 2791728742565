import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { Alert, Box, Button, MobileStepper } from "@mui/material";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { useState } from "react";
import TripSelector from "./TripSelector";
import DateSelector from "./DateSelector";
import TimeAndPassengersSelector from "./TimeAndPassengersSelector";
import SubmitFormStep from "./SubmitFormStep";
import { useTranslation } from "react-i18next";
import ExtrasSelector from "./ExtrasSelector";
import { addHours } from "date-fns";
import { TripContext } from "../ContextProviders/BookingContextProvider";

const BookingStepMobile = ({
  onChangeStep,
  selectedDate,
  loadingSlots,
  onSelectedDate,
  slots,
  extras,
  errors,
  booking,
  onChange,
  selectedSlot,
  onExtraChange,
  onSubmit,
  noSlotsAvailable,
}) => {
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState(0);
  const [message, setMessage] = useState("");
  const { selectedTrip } = useContext(TripContext);

  const handleBack = () => {
    onChangeStep();
    setActiveStep(activeStep - 1);
  };

  const extrasAvailables = () => {
    if (extras.length === 0) return false;

    if (addHours(new Date(), 48) > new Date(selectedSlot.slotDate.startDate))
      return false;

    return true;
  };

  const handleNext = (bypassValidation) => {
    switch (activeStep) {
      case 0:
        if (!selectedTrip && !bypassValidation) {
          setMessage(t("booking-section.form.message-step1"));
          onChangeStep();
        } else {
          setActiveStep(activeStep + 1);
          setMessage("");
        }
        break;
      case 1:
        if (!booking.slotId && !bypassValidation) {
          setMessage(t("booking-section.form.message-step2-mobile"));
          onChangeStep();
        } else {
          setActiveStep(activeStep + 1);
          setMessage("");
        }
        break;
      case 2:
        if (
          !bypassValidation &&
          (!booking.departureOriginTime ||
            !booking.departureDestinationTime ||
            booking.numberOfAdults + booking.numberOfChildren === 0)
        ) {
          setMessage(t("booking-section.form.message-step3-mobile"));
          onChangeStep();
        } else {
          if (extrasAvailables()) {
            setActiveStep(activeStep + 1);
          } else {
            setActiveStep(activeStep + 2);
          }
          setMessage("");
        }
        break;
      case 3:
        setActiveStep(activeStep + 1);
        break;
      default:
        break;
    }
  };

  const handleOnSelectedDate = (event) => {
    handleNext(true);
    onSelectedDate(event);
  };

  useEffect(() => {
    if (selectedTrip) {
      handleNext(true);
    }
  }, [selectedTrip]);

  return (
    <>
      <Box display="flex" justifyContent="center" alignItems="center">
        <MobileStepper
          variant="progress"
          steps={5}
          position="static"
          activeStep={activeStep}
          sx={{ maxWidth: 400, flexGrow: 1 }}
          nextButton={
            activeStep === 4 ? (
              <Button
                size="small"
                onClick={onSubmit}
                disabled={activeStep === 5}
              >
                {t("booking-section.form.submit")}
                <KeyboardArrowRight />
              </Button>
            ) : (
              <Button
                size="small"
                onClick={() => handleNext(false)}
                disabled={activeStep === 5}
              >
                {t("booking-section.form.next")}
                <KeyboardArrowRight />
              </Button>
            )
          }
          backButton={
            <Button
              size="small"
              onClick={handleBack}
              disabled={activeStep === 0}
            >
              <KeyboardArrowLeft />
              {t("booking-section.form.back")}
            </Button>
          }
        />
      </Box>
      {activeStep === 0 && <TripSelector />}
      {activeStep === 1 && (
        <DateSelector
          selectedDate={selectedDate}
          loadingSlots={loadingSlots}
          onSelectedDate={handleOnSelectedDate}
          slots={slots}
        />
      )}
      {activeStep === 2 && (
        <Box mt={3}>
          <TimeAndPassengersSelector
            errors={errors}
            booking={booking}
            onChange={onChange}
            selectedSlot={selectedSlot}
            noSlotsAvailable={noSlotsAvailable}
          />
        </Box>
      )}
      {activeStep === 3 && (
        <ExtrasSelector
          booking={booking}
          selectedSlot={selectedSlot}
          extras={extras}
          onExtraChange={onExtraChange}
        />
      )}
      {activeStep === 4 && (
        <SubmitFormStep errors={errors} booking={booking} onChange={onChange} />
      )}
      {message && <Alert severity="warning">{message}</Alert>}
      <Box display="flex" justifyContent="center" alignItems="center">
        <MobileStepper
          variant="progress"
          steps={5}
          position="static"
          activeStep={activeStep}
          sx={{ maxWidth: 400, flexGrow: 1 }}
          nextButton={
            activeStep === 4 ? (
              <Button
                size="small"
                onClick={onSubmit}
                disabled={activeStep === 5}
              >
                {t("booking-section.form.submit")}
                <KeyboardArrowRight />
              </Button>
            ) : (
              <Button
                size="small"
                onClick={() => handleNext(false)}
                disabled={activeStep === 5}
              >
                {t("booking-section.form.next")}
                <KeyboardArrowRight />
              </Button>
            )
          }
          backButton={
            <Button
              size="small"
              onClick={handleBack}
              disabled={activeStep === 0}
            >
              <KeyboardArrowLeft />
              {t("booking-section.form.back")}
            </Button>
          }
        />
      </Box>
    </>
  );
};

BookingStepMobile.propTypes = {
  bookingForTPV: PropTypes.object,
  loadingSlots: PropTypes.bool.isRequired,
  selectedDate: PropTypes.any.isRequired,
  onSelectedDate: PropTypes.func.isRequired,
  slots: PropTypes.array.isRequired,
  extras: PropTypes.array.isRequired,
  errors: PropTypes.object.isRequired,
  booking: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  selectedSlot: PropTypes.object,
  onChangeStep: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onExtraChange: PropTypes.func.isRequired,
  noSlotsAvailable: PropTypes.bool.isRequired,
};

export default BookingStepMobile;
