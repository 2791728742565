import React from "react";
import PropTypes from "prop-types";
import TripImage from "./TripImage";
import TripInfo from "./TripInfo";

const TripImageLeft = ({ tripInformation }) => {
  return (
    <>
      <TripImage tripInformation={tripInformation} />
      <TripInfo tripInformation={tripInformation} />
    </>
  );
};

TripImageLeft.propTypes = {
  tripInformation: PropTypes.object,
};

export default TripImageLeft;
