import React, { useState } from "react";
import SectionTitle from "../Common/SectionTitle";
import map from "../../assets/images/features/map.webp";
import { useTranslation } from "react-i18next";
import ContactForm from "./ContactForm";
import { sendMessage } from "../../api/contactApi";
import { Alert, Box, Container, Grid, Snackbar } from "@mui/material";
import { Email, LocationOn, Phone, Schedule } from "@mui/icons-material";

const defaultContact = {
  name: "",
  email: "",
  phoneNumber: "",
  message: "",
};

const GetInTouch = () => {
  const { t } = useTranslation();
  const [errors, setErrors] = useState({});
  const [contact, setContact] = useState(defaultContact);
  const [show, setShow] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const handleChange = (event) => {
    const { name, value } = event.target;

    setContact({ ...contact, [name]: value });
  };

  const handleSubmit = () => {
    if (!isFormValid()) return;

    sendMessage(contact)
      .then(() => {
        setToastMessage(t("contact-section.toast.message-success"));
        setShow(true);
        setContact(defaultContact);
      })
      .catch(() => {
        setToastMessage(t("contact-section.toast.message-error"));
        setShow(true);
        setContact(defaultContact);
      });
  };

  const isFormValid = () => {
    const { name, email, message } = contact;

    let errors = {};

    if (!name) errors.name = t("contact-section.errors.name");
    if (!email) errors.email = t("contact-section.errors.email");
    if (!message) errors.message = t("contact-section.errors.message");

    setErrors(errors);

    return Object.keys(errors).length === 0;
  };

  return (
    <>
      <section className="section" id="contact">
        <Container maxWidth="lg">
          <SectionTitle
            title={t("contact-section.title")}
            subtitle={t("contact-section.subTitle")}
            desc={t("contact-section.description")}
          />
          <Grid
            sx={{ marginTop: "40px" }}
            container
            columnSpacing={2}
            rowSpacing={2}
          >
            <Grid item lg={5}>
              <div className="contact-info">
                <div
                  style={{
                    backgroundImage: `url(${map})`,
                    backgroundRepeat: "no-repeat",
                  }}
                >
                  <Box sx={{ display: "flex" }}>
                    <LocationOn className="text-primary" />
                    <Box className="ml-4">
                      <p className="text-muted">
                        Muelle Nuevo, s/n. 07470 Pollença
                        <br />
                        Mallorca (Illes Balears)
                      </p>
                    </Box>
                  </Box>

                  <Box sx={{ display: "flex" }} className="mt-4">
                    <Phone className="text-primary" />
                    <Box className="ml-4">
                      <p className="text-muted">Phone: +34 971 864 014</p>
                    </Box>
                  </Box>

                  <Box sx={{ display: "flex" }} className="mt-4">
                    <Schedule className="text-primary" />
                    <Box className="ml-4">
                      <p className="text-muted">Monday-friday: 9.00-17.00</p>
                    </Box>
                  </Box>

                  <Box sx={{ display: "flex" }} className=" mt-4">
                    <Email className="text-primary" />
                    <Box className="ml-4">
                      <p className="text-muted">
                        Email: info@lanchaslagaviota.com
                      </p>
                    </Box>
                  </Box>
                </div>
              </div>
            </Grid>

            <Grid item lg={7}>
              <ContactForm
                errors={errors}
                contact={contact}
                onChange={handleChange}
                onSubmit={handleSubmit}
              />
              <Snackbar
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                open={show}
                autoHideDuration={3000}
                onClose={() => setShow(false)}
              >
                <Alert
                  onClose={() => setShow(false)}
                  severity="info"
                  sx={{ width: "100%" }}
                >
                  {toastMessage}
                </Alert>
              </Snackbar>
            </Grid>
          </Grid>
        </Container>
      </section>
    </>
  );
};

export default GetInTouch;
