import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";

const PaymentForm = ({ tpvOptions, booking }) => {
  const formRef = useRef();

  useEffect(() => {
    formRef.current.submit();
  }, [tpvOptions, booking]);

  return (
    <form
      ref={formRef}
      action={tpvOptions.urlCompra}
      encType="application/x-www-form-urlencoded"
    >
      <input name="MerchantID" type="hidden" value={tpvOptions.merchantID} />
      <input name="AcquirerBIN" type="hidden" value={tpvOptions.acquirerBIN} />
      <input name="TerminalID" type="hidden" value={tpvOptions.terminalID} />
      <input name="URL_OK" type="hidden" value={tpvOptions.urlOK} />
      <input name="URL_NOK" type="hidden" value={tpvOptions.urlNOK} />
      <input name="Firma" type="hidden" value={tpvOptions.signature} />
      <input name="Cifrado" type="hidden" value={tpvOptions.cifrado} />
      <input name="Num_operacion" type="hidden" value={booking.ticket} />
      <input
        name="Importe"
        type="hidden"
        value={booking.amount.amountFormatted}
      />
      <input name="TipoMoneda" type="hidden" value={tpvOptions.tipoMoneda} />
      <input name="Exponente" type="hidden" value={tpvOptions.exponente} />
      <input name="Pago_soportado" type="hidden" value="SSL" />
      <input name="Idioma" type="hidden" value="1" />
    </form>
  );
};

PaymentForm.propTypes = {
  tpvOptions: PropTypes.object.isRequired,
  booking: PropTypes.object.isRequired,
  urlOK: PropTypes.string.isRequired,
  urlNOK: PropTypes.string.isRequired,
};

export default PaymentForm;
