import React, { useContext } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Button,
} from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { Add, DirectionsBike, ShoppingCart } from "@mui/icons-material";
import { Link } from "gatsby";
import { TripContext } from "../ContextProviders/BookingContextProvider";

const TripInfo = ({ tripInformation }) => {
  const { selectTrip } = useContext(TripContext);
  const { t } = useTranslation();

  const handleOnClick = () => {
    selectTrip(tripInformation.tripObjectId);
    window.scrollTo(0, window.document.getElementById("booking").offsetTop);
  };

  return (
    <Grid sx={{ width: "100%" }} item md={6}>
      <Card>
        <CardHeader
          sx={{ bgcolor: tripInformation.color, color: "#fff" }}
          title={t(tripInformation.title)}
        ></CardHeader>
        <CardContent sx={{ minHeight: "344px" }}>
          <Trans i18nKey={tripInformation.description}>
            <p>First paragraph</p>
            <p>Second paragraph</p>
          </Trans>
          <ul className="mt-3">
            <li>
              <Box sx={{ fontSize: "12px", textTransform: "uppercase" }}>
                <Trans i18nKey={tripInformation.priceAdult}>
                  Price per adult: <strong>17 €</strong>
                </Trans>
              </Box>
            </li>
            <li>
              <Box sx={{ fontSize: "12px", textTransform: "uppercase" }}>
                <Trans i18nKey={tripInformation.priceChild}>
                  Price per adult: <strong>17 €</strong>
                </Trans>
              </Box>
            </li>
            <li>
              <Box sx={{ fontSize: "12px", textTransform: "uppercase" }}>
                <Trans i18nKey="trips.childrens-under-5">
                  Price per adult: <strong>17 €</strong>
                </Trans>
              </Box>
            </li>
            <li>
              <Box sx={{ fontSize: "12px", textTransform: "uppercase" }}>
                <Trans i18nKey={tripInformation.duration}>
                  Duration: <strong>1 h</strong>
                </Trans>
              </Box>
            </li>
            <li>
              <Box sx={{ fontSize: "12px", textTransform: "uppercase" }}>
                <Trans i18nKey="trips.general">
                  You can bring you bike <DirectionsBike fontSize="small" />
                </Trans>
              </Box>
            </li>
          </ul>
        </CardContent>
        <CardActions>
          <Button
            variant="text"
            startIcon={<Add />}
            component={Link}
            to={tripInformation.url}
          >
            {t("trips.more-info")}
          </Button>
          <Button
            variant="text"
            startIcon={<ShoppingCart />}
            onClick={handleOnClick}
          >
            {t("trips.book-it")}
          </Button>
        </CardActions>
      </Card>
    </Grid>
  );
};

TripInfo.propTypes = {
  tripInformation: PropTypes.object,
};

export default TripInfo;
