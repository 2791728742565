import React from "react";
import PropTypes from "prop-types";
import {
  Alert,
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";

const TimeAndPassengersSelector = ({
  errors,
  booking,
  onChange,
  selectedSlot,
  noSlotsAvailable,
}) => {
  const { t } = useTranslation();
  return (
    <>
      {noSlotsAvailable && (
        <Box mb={2}>
          <Alert severity="warning">
            {t("booking-section.form.message-no-trips")}
          </Alert>
        </Box>
      )}
      <Box mb={2}>
        <FormControl error={!!errors.departureOriginTime} fullWidth>
          <InputLabel>
            {t("booking-section.form.booking-origin-time")}
          </InputLabel>
          <Select
            name="departureOriginTime"
            value={booking.departureOriginTime}
            onChange={onChange}
            error={!!errors.departureOriginTime}
            label={t("booking-section.form.booking-origin-time")}
          >
            <MenuItem value="" selected disabled>
              <em>{t("booking-section.form.booking-origin-time")}</em>
            </MenuItem>
            {selectedSlot &&
              selectedSlot.departureOriginTimes.map((x, index) => (
                <MenuItem key={index} value={x}>
                  {x}
                </MenuItem>
              ))}
          </Select>
          <FormHelperText>{errors.departureOriginTime}</FormHelperText>
        </FormControl>
      </Box>
      <Box mb={2}>
        <FormControl error={!!errors.departureDestinationTime} fullWidth>
          <InputLabel>
            {t("booking-section.form.booking-destination-time")}
          </InputLabel>
          <Select
            name="departureDestinationTime"
            value={booking.departureDestinationTime}
            onChange={onChange}
            error={!!errors.departureDestinationTime}
            label={t("booking-section.form.booking-destination-time")}
          >
            <MenuItem value="" selected disabled>
              <em>{t("booking-section.form.booking-destination-time")}</em>
            </MenuItem>
            {selectedSlot &&
              selectedSlot.departureDestinationTimes.map((x, index) => (
                <MenuItem key={index} value={x}>
                  {x}
                </MenuItem>
              ))}
          </Select>
          <FormHelperText>{errors.departureDestinationTime}</FormHelperText>
        </FormControl>
      </Box>
      <Box mb={2}>
        <form autoComplete="off">
          <TextField
            autoComplete="off"
            fullWidth
            name="numberOfAdults"
            required
            type="number"
            id="numberOfAdults"
            value={booking.numberOfAdults.toString()}
            error={!!errors.people}
            helperText={errors.people}
            onChange={onChange}
            label={t("booking-section.form.booking-number-of-adults")}
          />
        </form>
      </Box>
      <Box>
        <form autoComplete="off">
          <TextField
            autoComplete="off"
            fullWidth
            name="numberOfChildren"
            id="numberOfChildren"
            required
            type="number"
            value={booking.numberOfChildren.toString()}
            error={!!errors.people}
            helperText={errors.people}
            onChange={onChange}
            label={t("booking-section.form.booking-number-of-children")}
          />
        </form>
      </Box>
    </>
  );
};

TimeAndPassengersSelector.propTypes = {
  errors: PropTypes.object.isRequired,
  booking: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  selectedSlot: PropTypes.object,
  noSlotsAvailable: PropTypes.bool.isRequired,
};

export default TimeAndPassengersSelector;
