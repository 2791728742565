import React from "react";
import PropTypes from "prop-types";
import TripImageLeft from "./TripImageLeft";
import TripImageRight from "./TripImageRight";

const TripDistributor = ({ tripInformation, index }) => {
  return (
    <>
      {index % 2 === 0 ? (
        <TripImageLeft tripInformation={tripInformation} />
      ) : (
        <TripImageRight tripInformation={tripInformation} />
      )}
    </>
  );
};

TripDistributor.propTypes = {
  tripInformation: PropTypes.object,
  index: PropTypes.number.isRequired,
};

export default TripDistributor;
