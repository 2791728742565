import React from "react";
import PropTypes from "prop-types";

const SectionTitle = ({ subtitle, isLight, title, desc }) => {
  return (
    <>
      <div className="header-title text-center">
        {subtitle && (
          <p className="text-uppercase text-muted mb-2">{subtitle}</p>
        )}
        <h2
          className={
            isLight === true ? "text-uppercase text-white" : "text-uppercase"
          }
        >
          {title}
        </h2>
        <div className="title-border mt-3"></div>
        <p
          className={
            isLight === true
              ? "title-desc text-white mt-3"
              : "title-desc text-muted mt-3"
          }
        >
          {desc}
        </p>
      </div>
    </>
  );
};

SectionTitle.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  isLight: PropTypes.bool,
  desc: PropTypes.string,
};

export default SectionTitle;
