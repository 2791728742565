import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { Alert, Step, StepLabel, Stepper, Box, Button } from "@mui/material";
import TripSelector from "./TripSelector";
import SubmitFormStep from "./SubmitFormStep";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import DateAndTimeAndPassengerSelector from "./DateAndTimeAndPassengerSelector";
import ExtrasSelector from "./ExtrasSelector";
import { TripContext } from "../ContextProviders/BookingContextProvider";

const BoookingStepsDestkop = ({
  loadingSlots,
  selectedDate,
  onSelectedDate,
  slots,
  extras,
  errors,
  booking,
  onChange,
  selectedSlot,
  onChangeStep,
  onExtraChange,
  onSubmit,
  noSlotsAvailable,
}) => {
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState(0);
  const [message, setMessage] = useState("");
  const { selectedTrip } = useContext(TripContext);

  const handleBack = () => {
    onChangeStep();
    setActiveStep(activeStep - 1);
  };

  const handleNext = (bypassValidation) => {
    if (activeStep === 0 && !selectedTrip && !bypassValidation) {
      setMessage(t("booking-section.form.message-step1"));
    } else if (
      activeStep === 1 &&
      (!booking.slotId ||
        !booking.departureOriginTime ||
        !booking.departureDestinationTime ||
        booking.numberOfAdults + booking.numberOfChildren === 0)
    ) {
      onChangeStep();
      setMessage(t("booking-section.form.message-step2"));
    } else {
      setActiveStep(activeStep + 1);
      setMessage("");
    }
  };

  useEffect(() => {
    if (selectedTrip) {
      handleNext(true);
    }
  }, [selectedTrip]);

  return (
    <>
      <Stepper activeStep={activeStep}>
        <Step key="Choose a trip">
          <StepLabel>{t("booking-section.form.choose-trip")}</StepLabel>
        </Step>
        <Step key="Choose a date">
          <StepLabel>{t("booking-section.form.choose-date")}</StepLabel>
        </Step>
        <Step key="Choose extras">
          <StepLabel>{t("booking-section.form.choose-extra")}</StepLabel>
        </Step>
        <Step key="Book it">
          <StepLabel>{t("booking-section.form.book-it")}</StepLabel>
        </Step>
      </Stepper>
      {activeStep === 0 && <TripSelector />}
      {activeStep === 1 && (
        <DateAndTimeAndPassengerSelector
          selectedDate={selectedDate}
          loadingSlots={loadingSlots}
          onSelectedDate={onSelectedDate}
          slots={slots}
          errors={errors}
          booking={booking}
          onChange={onChange}
          selectedSlot={selectedSlot}
          noSlotsAvailable={noSlotsAvailable}
        />
      )}
      {activeStep === 2 && (
        <ExtrasSelector
          booking={booking}
          selectedSlot={selectedSlot}
          extras={extras}
          onExtraChange={onExtraChange}
        />
      )}
      {activeStep === 3 && (
        <SubmitFormStep errors={errors} booking={booking} onChange={onChange} />
      )}
      {message && <Alert severity="warning">{message}</Alert>}
      <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
        <Button
          color="inherit"
          disabled={activeStep === 0}
          onClick={handleBack}
          sx={{ mr: 1 }}
        >
          {t("booking-section.form.back")}
        </Button>
        <Box sx={{ flex: "1 1 auto" }} />
        {activeStep === 3 ? (
          <Button onClick={onSubmit}>{t("booking-section.form.submit")}</Button>
        ) : (
          <Button onClick={handleNext}>{t("booking-section.form.next")}</Button>
        )}
      </Box>
    </>
  );
};

BoookingStepsDestkop.propTypes = {
  bookingForTPV: PropTypes.object,
  loadingSlots: PropTypes.bool.isRequired,
  selectedDate: PropTypes.any.isRequired,
  onSelectedDate: PropTypes.func.isRequired,
  slots: PropTypes.array.isRequired,
  extras: PropTypes.array.isRequired,
  errors: PropTypes.object.isRequired,
  booking: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  selectedSlot: PropTypes.object,
  onChangeStep: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onExtraChange: PropTypes.func.isRequired,
  noSlotsAvailable: PropTypes.bool.isRequired,
};

export default BoookingStepsDestkop;
