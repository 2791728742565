import React from "react";
import PropTypes from "prop-types";
import { Container, Grid } from "@mui/material";
import DateSelector from "./DateSelector";
import TimeAndPassengersSelector from "./TimeAndPassengersSelector";

const DateAndTimeAndPassengerSelector = ({
  loadingSlots,
  selectedDate,
  onSelectedDate,
  slots,
  errors,
  booking,
  onChange,
  selectedSlot,
  noSlotsAvailable,
}) => {
  return (
    <Container className="mt-5">
      <Grid container>
        <Grid item lg={6} xs={12}>
          <DateSelector
            loadingSlots={loadingSlots}
            selectedDate={selectedDate}
            onSelectedDate={onSelectedDate}
            slots={slots}
          />
        </Grid>
        <Grid item lg={6} xs={12}>
          <TimeAndPassengersSelector
            noSlotsAvailable={noSlotsAvailable}
            errors={errors}
            booking={booking}
            onChange={onChange}
            selectedSlot={selectedSlot}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

DateAndTimeAndPassengerSelector.propTypes = {
  loadingSlots: PropTypes.bool.isRequired,
  selectedDate: PropTypes.any.isRequired,
  onSelectedDate: PropTypes.func.isRequired,
  slots: PropTypes.array.isRequired,
  errors: PropTypes.object.isRequired,
  booking: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  selectedSlot: PropTypes.object,
  noSlotsAvailable: PropTypes.bool.isRequired,
};

export default DateAndTimeAndPassengerSelector;
