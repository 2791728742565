import React, { useEffect } from "react";
import GetInTouch from "../components/GetInTouch/GetInTouch";
import Banner from "../components/Banner/Banner";
import Trips from "../components/Trips/Trips";
import Bookings from "../components/Booking/Bookings";
import Page from "../components/Page";
import BookingSummary from "../components/Booking/BookingSummary";

const IndexPage = () => {
  const generateCookie = (source) => {
    const date = new Date();
    date.setTime(date.getTime() + 365 * 24 * 60 * 60 * 1000);
    let expires = "expires=" + date.toUTCString();

    document.cookie = `gaviota-source=${source};${expires}`;
  };

  useEffect(() => {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });

    let source = params.source;

    if (source) {
      generateCookie(source);
    }
  }, []);

  return (
    <Page
      title="Home"
      description="Discover the north coast of Mallorca with Lanchas La Gaviota! You will be able to enjoy the abundant flora and fauna thanks to our glass bottom boat! Our trips are suited for everyone, so come with your loved ones and share an unforgettable experience during your holidays with Lanchas La Gaviota!"
      isFrontPage
    >
      <Banner />

      <Trips />

      <Bookings />

      <GetInTouch />

      <BookingSummary />
    </Page>
  );
};

export default IndexPage;
