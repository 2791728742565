import React, { useContext } from "react";
import {
  Box,
  Grid,
  Container,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { TripContext } from "../ContextProviders/BookingContextProvider";
import {
  presentationTrips,
  defaultPresentationTrip,
} from "../Trips/trips-data";

const TripSelector = () => {
  const { trips, selectTrip, selectedTrip } = useContext(TripContext);
  const { t } = useTranslation();

  const handleOnClick = (id) => {
    selectTrip(id);
  };

  return (
    <>
      <Container className="mt-3">
        <Grid container>
          {trips &&
            trips.map((x, index) => {
              let trip = presentationTrips.find(
                (trip) => trip.tripObjectId === x.id
              );

              if (trip == null) trip = defaultPresentationTrip;

              return (
                <Grid item key={index} xl={4} lg={4} md={6} xm={12} xs={12}>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Card
                      key={x.id}
                      className={
                        selectedTrip && selectedTrip.id === x.id
                          ? "booking-trip-card selected"
                          : "booking-trip-card"
                      }
                    >
                      <CardActionArea onClick={() => handleOnClick(x.id)}>
                        <CardMedia
                          src={trip.backgroundImage}
                          alt="trip thumbnail"
                          component="img"
                          loading="lazy"
                        ></CardMedia>
                        <CardContent>
                          <Box className="booking-trip-card-name">{x.name}</Box>
                          <Box>
                            {t("booking-section.form.price-per-adult")}:{" "}
                            {x.pricePerAdult.amount}€
                          </Box>
                          <Box>
                            {t("booking-section.form.price-per-child")}:{" "}
                            {x.pricePerChild.amount}€
                          </Box>
                          <Box>{t("booking-section.form.free-under-five")}</Box>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Box>
                </Grid>
              );
            })}
        </Grid>
      </Container>
    </>
  );
};

export default TripSelector;
