import React, { useContext } from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import {
  FormControlLabel,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Container,
  Box,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { TripContext } from "../ContextProviders/BookingContextProvider";

const SubmitFormStep = ({ booking, errors, onChange }) => {
  const { selectedTrip } = useContext(TripContext);
  const { t } = useTranslation();

  return (
    <Container className="mt-5">
      <Box>
        <TextField
          fullWidth
          name="email"
          required
          type="email"
          value={booking.email}
          error={!!errors.email}
          helperText={errors.email}
          onChange={onChange}
          label={t("booking-section.form.booking-email")}
        />
      </Box>
      <Box mt={2}>
        <TextField
          fullWidth
          name="fullName"
          required
          type="text"
          value={booking.fullName}
          error={!!errors.fullName}
          helperText={errors.fullName}
          onChange={onChange}
          label={t("booking-section.form.booking-fullname")}
        />
      </Box>
      <Box mt={2}>
        <TextField
          fullWidth
          name="phone"
          type="text"
          value={booking.phone}
          onChange={onChange}
          label={t("booking-section.form.booking-phone")}
        />
      </Box>
      <Box mt={2}>
        <TextField
          fullWidth
          name="addressLine"
          type="text"
          value={booking.addressLine}
          onChange={onChange}
          label={t("booking-section.form.booking-address-line")}
        />
      </Box>
      <Box mt={2}>
        <TextField
          fullWidth
          name="postCode"
          value={booking.postCode}
          onChange={onChange}
          type="text"
          label={t("booking-section.form.booking-postcode")}
        />
      </Box>
      <Box mt={2}>
        <TextField
          fullWidth
          name="country"
          value={booking.country}
          onChange={onChange}
          type="text"
          label={t("booking-section.form.booking-country")}
        />
      </Box>
      <Box mt={2}>
        <FormControlLabel
          control={
            <Switch
              inputProps={{ "aria-label": "controlled" }}
              name="applyResidentsDiscount"
              checked={booking.applyResidentsDiscount}
              onChange={onChange}
            />
          }
          label={t("booking-section.form.booking-resident")}
        />
      </Box>
      {selectedTrip &&
        booking.slotId &&
        (booking.numberOfAdults > 0 || booking.numberOfChildren > 0) && (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  {t("booking-section.form.table.description")}
                </TableCell>
                <TableCell align="right">
                  {t("booking-section.form.table.quantity")}
                </TableCell>
                <TableCell align="right">
                  {t("booking-section.form.table.unit")}
                </TableCell>
                <TableCell align="right">
                  {t("booking-section.form.table.sum")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {booking.numberOfAdults > 0 && (
                <TableRow>
                  <TableCell>
                    {selectedTrip.name} -{" "}
                    {t("booking-section.form.table.adult")}
                  </TableCell>
                  <TableCell align="right">{booking.numberOfAdults}</TableCell>
                  <TableCell align="right">
                    {selectedTrip.pricePerAdult.amount} €
                  </TableCell>
                  <TableCell align="right">
                    {(
                      booking.numberOfAdults * selectedTrip.pricePerAdult.amount
                    ).toFixed(2)}{" "}
                    €
                  </TableCell>
                </TableRow>
              )}
              {booking.numberOfChildren > 0 && (
                <TableRow>
                  <TableCell>
                    {selectedTrip.name} -{" "}
                    {t("booking-section.form.table.child")}
                  </TableCell>
                  <TableCell align="right">
                    {booking.numberOfChildren}
                  </TableCell>
                  <TableCell align="right">
                    {selectedTrip.pricePerChild.amount} €
                  </TableCell>
                  <TableCell align="right">
                    {(
                      booking.numberOfChildren *
                      selectedTrip.pricePerChild.amount
                    ).toFixed(2)}{" "}
                    €
                  </TableCell>
                </TableRow>
              )}

              <TableRow>
                <TableCell colSpan={3} align="right">
                  {t("booking-section.form.table.subTotal")}
                </TableCell>
                <TableCell align="right">
                  {(
                    booking.numberOfChildren *
                      selectedTrip.pricePerChild.amount +
                    booking.numberOfAdults * selectedTrip.pricePerAdult.amount
                  ).toFixed(2)}{" "}
                  €
                </TableCell>
              </TableRow>
              {!booking.ignoreDiscounts &&
                !booking.applyResidentsDiscount &&
                booking.applyWebDiscount && (
                  <TableRow>
                    <TableCell colSpan={3} align="right">
                      {t("booking-section.form.table.webDiscount")}
                    </TableCell>
                    <TableCell align="right">
                      {(
                        (((booking.numberOfChildren *
                          selectedTrip.pricePerChild.amount +
                          booking.numberOfAdults *
                            selectedTrip.pricePerAdult.amount) *
                          10) /
                          100) *
                        -1
                      ).toFixed(2)}{" "}
                      €
                    </TableCell>
                  </TableRow>
                )}
              {!booking.ignoreDiscounts && booking.applyResidentsDiscount && (
                <TableRow>
                  <TableCell colSpan={3} align="right">
                    {t("booking-section.form.table.residentsDiscount")}
                  </TableCell>
                  <TableCell align="right">
                    {(
                      (((booking.numberOfChildren *
                        selectedTrip.pricePerChild.amount +
                        booking.numberOfAdults *
                          selectedTrip.pricePerAdult.amount) *
                        20) /
                        100) *
                      -1
                    ).toFixed(2)}{" "}
                    €
                  </TableCell>
                </TableRow>
              )}
              {booking.extras.length > 0 &&
                booking.extras.map((x, index) => (
                  <TableRow key={index}>
                    <TableCell>{x.title}</TableCell>
                    <TableCell align="right">{x.amount}</TableCell>
                    <TableCell align="right">{x.price.amount} €</TableCell>
                    <TableCell align="right">
                      {(x.amount * x.price.amount).toFixed(2)} €
                    </TableCell>
                  </TableRow>
                ))}
              <TableRow>
                <TableCell colSpan={3} align="right">
                  {t("booking-section.form.table.total")}
                </TableCell>
                <TableCell align="right">
                  {(
                    booking.numberOfChildren *
                      selectedTrip.pricePerChild.amount +
                    booking.numberOfAdults * selectedTrip.pricePerAdult.amount +
                    (!booking.ignoreDiscounts &&
                    !booking.applyResidentsDiscount &&
                    booking.applyWebDiscount
                      ? 1
                      : 0) *
                      (((booking.numberOfChildren *
                        selectedTrip.pricePerChild.amount +
                        booking.numberOfAdults *
                          selectedTrip.pricePerAdult.amount) *
                        10) /
                        100) *
                      -1 +
                    (!booking.ignoreDiscounts && booking.applyResidentsDiscount
                      ? 1
                      : 0) *
                      (((booking.numberOfChildren *
                        selectedTrip.pricePerChild.amount +
                        booking.numberOfAdults *
                          selectedTrip.pricePerAdult.amount) *
                        20) /
                        100) *
                      -1 +
                    booking.extras.reduce(
                      (acc, item) => acc + item.price.amount * item.amount,
                      0
                    )
                  ).toFixed(2)}{" "}
                  €
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        )}
      <Box mt={2}>{t("legal-section.privacy-policy-agreement")}</Box>
      <Box mt={2}>
        <Link to="/contract-conditions">{t("legal-section.legal-notice")}</Link>
      </Box>
    </Container>
  );
};

SubmitFormStep.propTypes = {
  booking: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default SubmitFormStep;
