import React, { useContext, useState } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  ClickAwayListener,
  Fab,
  Popper,
  Typography,
} from "@mui/material";
import { TripContext } from "../ContextProviders/BookingContextProvider";
import { format } from "date-fns";
import { DirectionsBoat } from "@mui/icons-material";
import { orange } from "@mui/material/colors";
import { useTranslation } from "react-i18next";

const BookingSummary = () => {
  const { t } = useTranslation();
  const formatter = new Intl.NumberFormat("es-ES", {
    style: "currency",
    currency: "EUR",
  });
  const { selectedTrip, selectedDate, booking } = useContext(TripContext);

  const [open, setOpen] = useState(false);

  const handleClick = (event) => {
    setOpen((previousOpen) => !previousOpen);
  };

  return (
    <>
      <Fab
        sx={{
          color: "white",
          position: "fixed",
          right: 20,
          bottom: 20,
          backgroundColor: orange[600],
          "&:hover": { backgroundColor: orange[800] },
        }}
        variant="extended"
        onClick={handleClick}
      >
        <DirectionsBoat sx={{ mr: 1 }} />
        {t("booking-summary.title")}
      </Fab>
      <Popper
        style={{
          position: "fixed",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: 1,
        }}
        disablePortal
        open={open}
        transition
      >
        <ClickAwayListener onClickAway={handleClick}>
          <Card sx={{ boxShadow: 3 }}>
            <CardHeader title="Booking summary"></CardHeader>
            <CardContent>
              {!selectedTrip && (
                <Typography variant="caption" display="block" gutterBottom>
                  {t("booking-summary.no-trip")}
                </Typography>
              )}
              {selectedTrip && (
                <Typography variant="caption" display="block" gutterBottom>
                  <strong>{t("booking-summary.trip")}</strong>
                  {selectedTrip.name}
                </Typography>
              )}

              {selectedTrip && selectedDate && (
                <Typography variant="caption" display="block" gutterBottom>
                  <strong>{t("booking-summary.date")}</strong>
                  {format(selectedDate, "dd/MM/yyyy")}
                </Typography>
              )}

              {selectedTrip && selectedDate && (
                <Typography variant="caption" display="block" gutterBottom>
                  <strong>{t("booking-summary.adults")}</strong>
                  {booking.numberOfAdults}
                </Typography>
              )}

              {selectedTrip && selectedDate && (
                <Typography variant="caption" display="block" gutterBottom>
                  <strong>{t("booking-summary.children")}</strong>
                  {booking.numberOfChildren}
                </Typography>
              )}

              {selectedTrip && selectedDate && (
                <Typography variant="caption" display="block" gutterBottom>
                  <strong>{t("booking-summary.departure-time")}</strong>
                  {booking.departureOriginTime}
                </Typography>
              )}

              {selectedTrip && selectedDate && (
                <Typography variant="caption" display="block" gutterBottom>
                  <strong>{t("booking-summary.return-time")}</strong>
                  {booking.departureDestinationTime}
                </Typography>
              )}
              {selectedTrip && selectedDate && (
                <Typography variant="caption" display="block" gutterBottom>
                  <strong>{t("booking-summary.price")}</strong>
                  {formatter.format(
                    booking.numberOfChildren *
                      selectedTrip.pricePerChild.amount +
                      booking.numberOfAdults *
                        selectedTrip.pricePerAdult.amount +
                      (!booking.ignoreDiscounts &&
                      !booking.applyResidentsDiscount &&
                      booking.applyWebDiscount
                        ? 1
                        : 0) *
                        (((booking.numberOfChildren *
                          selectedTrip.pricePerChild.amount +
                          booking.numberOfAdults *
                            selectedTrip.pricePerAdult.amount) *
                          10) /
                          100) *
                        -1 +
                      (!booking.ignoreDiscounts &&
                      booking.applyResidentsDiscount
                        ? 1
                        : 0) *
                        (((booking.numberOfChildren *
                          selectedTrip.pricePerChild.amount +
                          booking.numberOfAdults *
                            selectedTrip.pricePerAdult.amount) *
                          20) /
                          100) *
                        -1 +
                      booking.extras.reduce(
                        (acc, item) => acc + item.price.amount * item.amount,
                        0
                      )
                  )}
                </Typography>
              )}
            </CardContent>
          </Card>
        </ClickAwayListener>
      </Popper>
    </>
  );
};

export default BookingSummary;
