import React from "react";
import PropTypes from "prop-types";
import { Button, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { orange } from "@mui/material/colors";

const ContactForm = ({ errors, contact, onSubmit, onChange }) => {
  const { t } = useTranslation();

  return (
    <>
      <TextField
        fullWidth
        name="name"
        required
        type="text"
        label={t("contact-section.form.name")}
        error={!!errors.name}
        helperText={errors.name}
        value={contact.name}
        onChange={onChange}
      />

      <TextField
        className="mt-3"
        fullWidth
        name="email"
        required
        type="text"
        label={t("contact-section.form.email")}
        error={!!errors.email}
        helperText={errors.email}
        value={contact.email}
        onChange={onChange}
      />

      <TextField
        className="mt-3"
        fullWidth
        name="phoneNumber"
        type="text"
        label={t("contact-section.form.phone")}
        error={!!errors.phoneNumber}
        helperText={errors.phoneNumber}
        value={contact.phoneNumber}
        onChange={onChange}
      />

      <TextField
        className="mt-3"
        multiline
        rows={4}
        fullWidth
        name="message"
        required
        type="text"
        label={t("contact-section.form.message")}
        error={!!errors.message}
        helperText={errors.message}
        value={contact.message}
        onChange={onChange}
      />

      <Button
        sx={{
          backgroundColor: orange[600],
          "&:hover": { backgroundColor: orange[800] },
        }}
        variant="contained"
        className="mt-3"
        onClick={onSubmit}
      >
        {t("contact-section.form.submit")}
      </Button>
    </>
  );
};

ContactForm.propTypes = {
  errors: PropTypes.object.isRequired,
  contact: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ContactForm;
