import React from "react";
import Carousel from "../Carousel/Carousel";

const Banner = () => {
  return (
    <>
      <section className="home-full" id="home">
        <Carousel />
      </section>
    </>
  );
};

export default Banner;
