import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { bookTrip } from "../../api/bookingApi";
import SectionTitle from "../Common/SectionTitle";
import BookingsForm from "./BookingsForm";
import { Container } from "@mui/material";
import { TripContext } from "../ContextProviders/BookingContextProvider";

const Bookings = () => {
  const { t, i18n } = useTranslation();
  const [errors, setErrors] = useState({});
  const [bookingForTPV, setBookingForTPV] = useState();
  const {
    selectedTrip,
    booking,
    setBooking,
    selectedSlot,
    extras,
    slots,
    loadingSlots,
    selectedDate,
    selectDate,
    selectExtra,
    applyResidentsDiscount,
    setNumberOfAdults,
    setNumberOfChildren,
  } = useContext(TripContext);

  useEffect(() => {
    const getCookie = (cname) => {
      let name = cname + "=";
      let decodedCookie = decodeURIComponent(document.cookie);
      let ca = decodedCookie.split(";");
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === " ") {
          c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    };

    let cookieValue = getCookie("gaviota-source");

    if (cookieValue && booking.source === "") {
      setBooking({ ...booking, source: cookieValue });
    }
  }, [booking, setBooking]);

  const handleOnSelectedDate = (date) => {
    selectDate(date);
    window.scrollTo(0, window.document.getElementById("booking").offsetTop);
  };

  const formIsValid = () => {
    const errors = {};

    const {
      slotId,
      email,
      fullName,
      numberOfAdults,
      numberOfChildren,
      departureOriginTime,
      departureDestinationTime,
    } = booking;

    if (!selectedTrip) errors.tripId = t("booking-section.errors.tripId");
    if (!slotId) errors.slotId = t("booking-section.errors.slotId");
    if (!departureOriginTime)
      errors.departureOriginTime = t(
        "booking-section.errors.departureOriginTime"
      );
    if (!departureDestinationTime)
      errors.departureDestinationTime = t(
        "booking-section.errors.departureDestinationTime"
      );
    if (!email) errors.email = t("booking-section.errors.email");
    if (!fullName) errors.fullName = t("booking-section.errors.fullName");
    if (numberOfAdults + numberOfChildren === 0)
      errors.people = t("booking-section.errors.people");

    setErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const handleOnChange = (event) => {
    const { name, value } = event.target;

    switch (name) {
      case "applyResidentsDiscount":
        applyResidentsDiscount(event.target.checked);
        break;
      case "numberOfAdults":
        setNumberOfAdults(parseInt(value));
        break;
      case "numberOfChildren":
        setNumberOfChildren(parseInt(value));
        break;
      default:
        setBooking({ ...booking, [name]: value });
        break;
    }
  };

  const handleChangeStep = () => {
    formIsValid();
  };

  const handleOnSubmit = (event) => {
    event.preventDefault();

    if (!formIsValid()) return;

    bookTrip({ ...booking, language: i18n.language }).then((x) => {
      setBookingForTPV(x.data);
    });
  };

  const handleOnExtraChange = (extraItem) => {
    selectExtra(extraItem);
  };

  return (
    <>
      <section className="section" id="booking">
        <Container maxWidth="lg">
          <SectionTitle
            title={t("booking-section.title")}
            subtitle={t("booking-section.subTitle")}
            desc={t("booking-section.description")}
          />

          <BookingsForm
            bookingForTPV={bookingForTPV}
            loadingSlots={loadingSlots}
            selectedDate={selectedDate}
            onSelectedDate={handleOnSelectedDate}
            slots={slots}
            extras={extras}
            errors={errors}
            booking={booking}
            onChange={handleOnChange}
            selectedSlot={selectedSlot}
            onChangeStep={handleChangeStep}
            onSubmit={handleOnSubmit}
            onExtraChange={handleOnExtraChange}
            noSlotsAvailable={!selectedSlot}
          />
        </Container>
      </section>
    </>
  );
};

export default Bookings;
