import React from "react";
import SectionTitle from "../Common/SectionTitle";
import { useTranslation } from "react-i18next";
import TripDistributor from "./TripDistributor";
import { Box, Container, Grid } from "@mui/material";
import TripImageRight from "./TripImageRight";
import { presentationTrips } from "./trips-data";

const Trips = () => {
  const { t } = useTranslation();

  return (
    <>
      <section className="section" id="trips">
        <Container maxWidth="lg">
          <SectionTitle
            title={t("boat-tours-section.title")}
            subtitle={t("boat-tours-section.subTitle")}
            desc={t("boat-tours-section.description")}
          />
          <Box sx={{ marginTop: "40px", display: { xs: "none", md: "block" } }}>
            <Grid container columnSpacing={2} rowSpacing={2}>
              {presentationTrips.map((tripInformation, index) => (
                <TripDistributor
                  key={index}
                  tripInformation={tripInformation}
                  index={index}
                />
              ))}
            </Grid>
          </Box>
          <Box sx={{ marginTop: "40px", display: { xs: "block", md: "none" } }}>
            <Grid container columnSpacing={2} rowSpacing={2}>
              {presentationTrips.map((tripInformation, index) => (
                <TripImageRight
                  key={index}
                  tripInformation={tripInformation}
                  index={index}
                />
              ))}
            </Grid>
          </Box>
        </Container>
      </section>
    </>
  );
};

export default Trips;
