import { Search } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import { MobileDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import React, { useContext, useEffect } from "react";
import { orange } from "@mui/material/colors";
import { enGB } from "date-fns/locale";
import { navigate } from "gatsby";
import { useTranslation } from "react-i18next";
import { SearchContext } from "../ContextProviders/SearchContextProvider";

const TripFinder = () => {
  const { setStartDate, setEndDate, startDate, endDate, clearContext } =
    useContext(SearchContext);

  const { t } = useTranslation();

  const handleOnClick = () => {
    navigate(`/search-results`);
  };

  useEffect(() => {
    clearContext();
  }, []);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
      <Box className="trip-finder">
        <Box>{t("trips.trip-finder-message")}</Box>
        <Box mb={1} sx={{ fontWeight: "bold" }} mt={2}>
          {t("trips.search-start")}
        </Box>
        <Box display="flex" alignItems="center" justifyContent="center">
          <MobileDatePicker
            sx={{ color: "white" }}
            slotProps={{ textField: { fullWidth: true } }}
            onChange={(date) => setStartDate(date)}
            disablePast
            closeOnSelect
          />
        </Box>
        <Box mb={1} sx={{ fontWeight: "bold" }} mt={2}>
          {t("trips.search-end")}
        </Box>
        <Box display="flex" alignItems="center" justifyContent="center">
          <MobileDatePicker
            disabled={startDate === null}
            slotProps={{ textField: { fullWidth: true } }}
            minDate={startDate}
            onChange={(date) => setEndDate(date)}
            closeOnSelect
          />
        </Box>
        <Box mt={2} display="flex" alignItems="center" justifyContent="center">
          <Button
            sx={{
              backgroundColor: orange[600],
              "&:hover": { backgroundColor: orange[800] },
            }}
            startIcon={<Search />}
            variant="contained"
            onClick={handleOnClick}
            disabled={startDate === null || endDate === null}
          >
            {t("trips.find-my-trip")}
          </Button>
        </Box>
      </Box>
    </LocalizationProvider>
  );
};

TripFinder.propTypes = {};

export default TripFinder;
