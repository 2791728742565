import React from "react";
import PropTypes from "prop-types";
import TripImage from "./TripImage";
import TripInfo from "./TripInfo";

const TripImageRight = ({ tripInformation }) => {
  return (
    <>
      <TripInfo tripInformation={tripInformation} />
      <TripImage tripInformation={tripInformation} />
    </>
  );
};

TripImageRight.propTypes = {
  tripInformation: PropTypes.object,
};

export default TripImageRight;
