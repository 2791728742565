import React, { useEffect, useState } from "react";
import carousel1 from "../../assets/images/carousel/carousel1.webp";
import carousel2 from "../../assets/images/carousel/carousel2.webp";
import carousel3 from "../../assets/images/carousel/carousel3.webp";
import { Box, Grid, Container } from "@mui/material";
import { useTranslation } from "react-i18next";
import { scrollTo } from "../Helpers/helpers";
import TripFinder from "../TripFinder/TripFinder";

const Carousel = () => {
  const { t } = useTranslation();
  const [currentSlide, setCurrentSlide] = useState(0);

  const handleOnClick = () => {
    scrollTo(
      window.pageYOffset,
      window.document.getElementById("trips").offsetTop,
      800
    );
  };

  const slides = [
    {
      image: carousel1,
      title: t("header.slide-1.title"),
      subTitle: t("header.slide-1.subTitle"),
    },
    {
      image: carousel2,
      title: t("header.slide-2.title"),
      subTitle: t("header.slide-2.subTitle"),
    },
    {
      image: carousel3,
      title: t("header.slide-3.title"),
      subTitle: t("header.slide-3.subTitle"),
    },
  ];

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentSlide((x) => (x === slides.length - 1 ? 0 : x + 1));
    }, 5000);
    return () => clearInterval(intervalId); //This is important
  }, [slides.length]);

  return (
    <>
      <div>
        <img
          alt="slide"
          className="img-responsive"
          src={slides[currentSlide].image}
        />
        <div className="home-center">
          <div className="home-desc-center">
            <Container
              maxWidth="lg"
              sx={{
                height: "calc(100% - 70px)",
                alignContent: "space-around",
              }}
            >
              <Grid container spacing={2}>
                <Grid item md={8} sm={12} xs={12}>
                  <div className="home-content text-white">
                    <h5 className="sub-title mt-4 text-white pt-2 text-uppercase">
                      {slides[currentSlide].title}
                    </h5>
                    <h1 className="title mt-4 text-white text-uppercase">
                      {slides[currentSlide].subTitle}
                    </h1>
                  </div>
                </Grid>
                <Grid item md={4} sm={12} xs={12}>
                  <Box
                    sx={{ display: { xs: "none", sm: "none", md: "block" } }}
                  >
                    <TripFinder></TripFinder>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </div>
        </div>
      </div>
      <button
        aria-label="previous-slide"
        onClick={() =>
          setCurrentSlide((x) => (x === 0 ? slides.length - 1 : x - 1))
        }
        className="carousel-left"
      ></button>
      <button
        aria-label="next-slide"
        onClick={() =>
          setCurrentSlide((x) => (x === slides.length - 1 ? 0 : x + 1))
        }
        className="carousel-right"
      ></button>
      <div className="dots">
        {slides.map((x, index) => {
          return (
            <div
              aria-hidden="true"
              key={index}
              onClick={() => setCurrentSlide(index)}
              className={`dot ${index === currentSlide ? "active" : ""}`}
            ></div>
          );
        })}
      </div>
      <button
        aria-label="Scroll down"
        onClick={handleOnClick}
        className="scroll-down-indicator"
      ></button>
    </>
  );
};

export default Carousel;
