import React from "react";
import PropTypes from "prop-types";
import { Grid, Paper } from "@mui/material";

const TripImage = ({ tripInformation }) => {
  return (
    <Grid sx={{ width: "100%" }} item md={6}>
      <Paper
        sx={{
          display: { xs: "none", md: "block" },
          height: "100%",
          backgroundImage: `url(${tripInformation.backgroundImage})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
        }}
      ></Paper>
      <Paper
        sx={{
          display: { xs: "block", md: "none" },
          height: "460px",
          backgroundImage: `url(${tripInformation.backgroundImage})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
        }}
      ></Paper>
    </Grid>
  );
};

TripImage.propTypes = {
  tripInformation: PropTypes.object,
};

export default TripImage;
