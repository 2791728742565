import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Card,
  CardMedia,
  TextField,
  Grid,
  CardHeader,
  Typography,
  CardActions,
  CardContent,
} from "@mui/material";
import { addHours } from "date-fns";
import { useTranslation } from "react-i18next";
import noImage from "../../assets/images/no-image-found-360x260.png";

const ExtrasSelector = ({ booking, extras, selectedSlot, onExtraChange }) => {
  const formatter = new Intl.NumberFormat("es-ES", {
    style: "currency",
    currency: "EUR",
  });
  const { t } = useTranslation();

  const handleOnChange = (extra, event) => {
    const { value } = event.target;

    let valueParsed = value === "" ? 0 : parseInt(value);

    if (valueParsed < 0) return;

    onExtraChange({
      extraId: extra.id,
      price: extra.price,
      title: extra.title,
      reference: extra.reference,
      description: extra.description,
      image: extra.image,
      amount: valueParsed,
    });
  };

  const extrasAvailables = () => {
    if (extras.length === 0) return false;

    if (addHours(new Date(), 48) > new Date(selectedSlot.slotDate.startDate))
      return false;

    return true;
  };

  return extrasAvailables() ? (
    <Box my={5}>
      <Grid container spacing={4}>
        {extras.map((x, key) => (
          <Grid item xs={12} sm={6} md={4}>
            <Card>
              <CardHeader
                titleTypographyProps={{
                  fontSize: 16,
                }}
                title={x.title}
                subheader={formatter.format(x.price.amount)}
              ></CardHeader>
              <CardMedia
                src={x.image ? x.image : noImage}
                alt="Extra"
                component="img"
                title="Extra"
              />
              <CardContent>
                <Typography
                  variant="caption"
                  display="block"
                  sx={{ whiteSpace: "pre-wrap" }}
                >
                  {x.description}
                </Typography>
              </CardContent>
              <CardActions>
                <form autoComplete="off" style={{ width: "100%" }}>
                  <TextField
                    fullWidth
                    name={x.extraId}
                    type="number"
                    label={x.title}
                    value={
                      booking.extras.find((e) => e.extraId === x.id)
                        ? booking.extras
                            .find((e) => e.extraId === x.id)
                            .amount.toString()
                        : "0"
                    }
                    onChange={(event) => handleOnChange(x, event)}
                  />
                </form>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  ) : (
    <Box my={5}>
      {extras.length === 0
        ? t("booking-section.form.message-no-extras")
        : t("booking-section.form.message-no-extras-date")}
    </Box>
  );
};

ExtrasSelector.propTypes = {
  booking: PropTypes.object.isRequired,
  extras: PropTypes.array.isRequired,
  selectedSlot: PropTypes.object.isRequired,
  onExtraChange: PropTypes.func.isRequired,
};

export default ExtrasSelector;
